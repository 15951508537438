<template>
  <v-container
      id="panels"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <base-material-card
            color="success"
            icon="mdi-card-account-details"
            title="Adding New User">
          <v-row>
            <v-col xs="9" sm="9" md="10"></v-col>
            <v-col xs="3" sm="3" md="2" class="text-right">
              <v-btn small right color="info" class="mr-0" @click="$router.push({name: 'Users' })">
                <v-icon small class="mr-1">
                  mdi-arrow-left
                </v-icon>
                Back
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <template v-if="loading">
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
              </template>
              <template v-else>
                <v-card
                    flat
                    class="mb-1"
                >
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                            label="First Name"
                            v-model="user.firstName"
                            prepend-icon="mdi-account"
                        />
                      </v-col>

                      <v-col cols="12" md="12">
                        <v-text-field
                            label="Last Name"
                            v-model="user.lastName"
                            prepend-icon="mdi-account"
                        />
                      </v-col>

                      <v-col cols="12" md="12">
                        <v-text-field
                            label="Email"
                            type="email"
                            v-model="user.email"
                            prepend-icon="mdi-mail"
                        />
                      </v-col>

                      <v-col cols="12" md="12">
                        <v-text-field
                            label="Password"
                            type="password"
                            v-model="user.password"
                            prepend-icon="mdi-lock"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="text-right pr-0 mr-0">
              <v-btn
                  class="mr-3"
                  color="success"
                  v-if="canAccess({module: 'saas', route: '/organization/users', method: 'put'})"
                  @click="addUser">
                <v-icon class="mr-1">mdi-content-save</v-icon>
                Save
              </v-btn>

            </v-col>
          </v-row>
        </base-material-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import usersMixins from "./mixins";
import globalMixins from "../../../../mixins/globalMixins";

export default {
  name: 'UsersAdd',

  mixins: [globalMixins, usersMixins],

  props: {},

  data: () => ({
    loading: true,
    user: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      username: '',
    }
  }),

  async created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },

    addUser() {
      const self = this;

      let newUser = {...this.user};
      newUser.username = newUser.email;

      //validate the payload before invoking the API
      if (!this.validateUserRecord(newUser)) {
        return false;
      }

      if (!this.validPassword(newUser.password)) {
        this.pushMessage({
          'type': 'error',
          'title': 'Validation Error',
          'text': 'The password should be at least 10 characters long and contain 1 uppercase letter, 1 lowercase letter, 1 special character and 1 digit.'
        });
        self.scrollToTop();
        return false;
      }

      let apiOptions = {
        url: `/saas/organization/users`,
        method: "put",
        params: newUser
      };

      self.getSendData(apiOptions)
          .then((response) => {
            self.pushMessage({
              type: 'success',
              title: `User Created`,
              text: `New User ${self.user.firstName} ${self.user.lastName} has been created!.`
            });
            self.scrollToTop();

            setTimeout(() => {
              self.$router.push({name: 'Users'});
            }, 2000);

          });
    }
  }
}
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}

</style>
